<div
    class="input-group date-picker-control"
    id="dateRangePicker"
    (click)="onDatepickerInteraction($event, dp, calendarMonth!)"
>
    <input
        class="form-control"
        id="dateRange"
        [placeholder]="placeholder"
        name="dp"
        [value]="formattedDateRange"
        ngbDatepicker
        #dp="ngbDatepicker"
        (dateSelect)="onDateSelection($event)"
        [displayMonths]="1"
        [dayTemplate]="t"
        [autoClose]="'outside'"
        (click)="dp.toggle(); isOpen = !isOpen"
        [readonly]="true"
        [minDate]="isDisableFutureDate ? null : todayDate"
        [maxDate]="isDisableFutureDate ? todayDate : null"
    />
    <button class="input-group-text" type="button" (click)="dp.toggle(); isOpen = !isOpen">
        <i *ngIf="from || to" class="feather icon-x icon-md text-muted cancel-icon" (click)="clearDate($event)"></i>
        <i class="feather icon-calendar icon-md text-muted fs-4"></i>
    </button>
</div>
<ng-template #t let-date="date" let-focused="focused">
    <span
        class="custom-day"
        [class.focused]="focused"
        [class.range]="isFrom(date) || isTo(date) || isInside(date) || isHovered(date)"
        [class.faded]="isHovered(date) || isInside(date)"
        (mouseenter)="hoveredDate = toDate(date)"
        (mouseleave)="hoveredDate = null"
        [class.current-month]="isInCurrentMonth(date)"
        [class.grayed-out]="!isInCurrentMonth(date)"
    >
        {{ date.day }}
    </span>
</ng-template>
