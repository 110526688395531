import { Component, EventEmitter, HostListener, Input, Output, SimpleChanges, ViewChild } from '@angular/core';
import {
    NgbDate,
    NgbDateParserFormatter,
    NgbDatepickerConfig,
    NgbDateStruct,
    NgbInputDatepicker,
} from '@ng-bootstrap/ng-bootstrap';
import { CustomDateParserFormatter } from '../datepicker/custom-date-adapter';
import moment from 'moment';
import { DateUtilService } from 'src/app/service/dateUtills.service';

@Component({
    selector: 'date-range-picker',
    templateUrl: './date-range-picker.component.html',
    styleUrl: './date-range-picker.component.scss',
    providers: [
        {
            provide: NgbDateParserFormatter,
            useClass: CustomDateParserFormatter,
        },
        {
            provide: NgbDatepickerConfig,
            useFactory: () => {
                const config = new NgbDatepickerConfig();
                config.firstDayOfWeek = 7; // Sunday
                return config;
            },
        },
    ],
})
export class DateRangePickerComponent {
    @ViewChild('dp') private datePicker: NgbInputDatepicker;
    from: NgbDateStruct | null = null; // Start date
    to: NgbDateStruct | null = null; // End date
    hoveredDate: NgbDateStruct | null = null;
    @Input() selectedFromDate: NgbDateStruct | null = null; // Allow parent to bind start date
    @Input() selectedToDate: NgbDateStruct | null = null; // Allow parent to bind end date
    @Input() placeholder = 'starting today';
    @Input() resetTrigger: boolean = false;
    @Input() displayFutureDate: boolean = false;
    @Input() isDisableFutureDate: boolean = false;
    @Input() calendarMonth: any;
    @Output() dateRangeSelection: EventEmitter<any> = new EventEmitter<{ from: any; to: any }>();
    isOpen = false;
    todayDate: any;
    private isDateSelected: boolean = false; // Flag to track if a date has been selected

    @HostListener('document:click', ['$event.target']) onClick(element: any) {
        const host = document.getElementById('dateRangePicker');
        if (this.datePicker && this.isOpen && !this.isDescendant(host, element) && this.isDateSelected) {
            this.emit(true);
        }
    }

    constructor(public dateUtilService: DateUtilService) {}

    ngOnInit() {
        const current = new Date();
        if (this.displayFutureDate || this.isDisableFutureDate) {
            this.todayDate = {
                year: current.getFullYear(),
                month: current.getMonth() + 1,
                day: current.getDate(),
            };
        } else {
            this.todayDate = null;
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.selectedFromDate && changes.selectedFromDate.currentValue) {
            this.from = this.toDate(changes.selectedFromDate.currentValue);
        }

        if (changes.selectedToDate && changes.selectedToDate.currentValue) {
            this.to = this.toDate(changes.selectedToDate.currentValue);
        }

        if (changes['resetTrigger'] && changes['resetTrigger'].currentValue) {
            this.resetDateRange();
        }
    }

    resetDateRange(): void {
        this.isDateSelected = true;
        this.from = null;
        this.to = null;
    }
    clearDate(event: MouseEvent): void {
        this.from = null;
        this.to = null;
        const dateRange = {
            from: null,
            to: null,
        };

        this.isDateSelected = true;
        this.dateRangeSelection.emit(dateRange);
        event.stopPropagation();
    }
    public emit(close?: boolean) {
        this.isDateSelected = false;
        const dateRange = {
            from: this.from,
            to: this.to,
        };

        if (close) {
            this.isOpen = false;
            this.datePicker.close();
            this.dateRangeSelection.emit(dateRange);
        }
    }

    private isDescendant(parent: any, child: any) {
        let node = child;
        while (node !== null) {
            if (node === parent) {
                return true;
            } else {
                node = node.parentNode;
            }
        }
        return false;
    }

    get formattedDateRange(): string {
        const fromFormatted = moment(this.from).format('MM-DD-YYYY');

        return this.to
            ? `${fromFormatted}` + ` - ` + `${moment(this.to).format('MM-DD-YYYY')}`
            : this.from
            ? `${fromFormatted}`
            : '';
    }

    onDateSelection(date: NgbDateStruct) {
        // Handle date selection logic
        const selectedDate = date;
        // Update the selected month and year
        this.selectedMonth = selectedDate.month;
        this.selectedYear = selectedDate.year;

        this.isDateSelected = true;

        if (!this.from && !this.to) {
            this.from = this.toDate(date);
        } else if (
            this.from &&
            !this.to &&
            (this.toMoment(date).isAfter(this.from) || this.toMoment(date).isSame(this.from))
        ) {
            this.to = this.toDate(date);
            this.emit(true);
        } else {
            this.to = null;
            this.from = this.toDate(date);
        }
    }

    toDate(dateStruct: NgbDateStruct): any {
        return dateStruct ? new Date(dateStruct.year, dateStruct.month - 1, dateStruct.day) : null;
    }

    toMoment(dateStruct: NgbDateStruct): moment.Moment {
        return moment(this.toDate(dateStruct));
    }

    isHovered = (date: NgbDateStruct) =>
        this.from &&
        !this.to &&
        this.hoveredDate &&
        this.toMoment(date).isAfter(this.from) &&
        this.toMoment(date).isBefore(this.hoveredDate);

    isInside = (date: NgbDateStruct) =>
        this.toMoment(date).isAfter(moment(this.from).startOf('day')) &&
        this.toMoment(date).isBefore(moment(this.to).startOf('day'));
    isFrom = (date: NgbDateStruct) => this.toMoment(date).isSame(this.from, 'd');
    isTo = (date: NgbDateStruct) => this.toMoment(date).isSame(this.to, 'd');
    // Variables to store the currently selected month and year
    selectedMonth: number = new Date().getMonth() + 1; // Default to the current month
    selectedYear: number = new Date().getFullYear(); // Default to the current year

    onDatepickerInteraction(event: MouseEvent, datepicker: any, date: any) {
        const target = event.target as HTMLElement;

        // Check if the click occurred inside specific datepicker components
        const isClickInsideDatepicker =
            target.closest('.ngb-dp-navigation') || // Navigation bar
            target.closest('.ngb-dp-month') || // Individual month cell
            target.closest('.ngb-dp-year') || // Year navigation
            target.closest('.ngb-dp-arrow') || // Arrow navigation
            target.closest('select.form-select'); // Dropdown for month/year

        if (isClickInsideDatepicker) {
            console.log('click inside');

            // Optional: Update selected month and year dynamically if needed
            this.updateSelectedMonthAndYearFromDropdown();
            return;
        }
        // Handle date selection logic
        if (datepicker && !this.from && date) {
            let formattedDate = this.dateUtilService.formatDateToYYYYMMDD(date);
            let selectedMonth = this.dateUtilService.parseDateString(formattedDate);
            this.selectedMonth = selectedMonth.month;
            this.selectedYear = selectedMonth.year;
            datepicker.navigateTo({
                year: this.selectedYear,
                month: this.selectedMonth,
            });
        }
        if (this.selectedFromDate) {
            this.selectedMonth = this.selectedFromDate.month;
            this.selectedYear = this.selectedFromDate.year;
            datepicker.navigateTo({
                year: this.selectedYear,
                month: this.selectedMonth,
            });
        }
    }

    // Method to update the selected month/year (if necessary)
    updateSelectedMonthAndYearFromDropdown() {
        const activeDropdowns = document.querySelectorAll('select.form-select');
        if (activeDropdowns.length === 2) {
            const monthDropdown = activeDropdowns[0] as HTMLSelectElement;
            const yearDropdown = activeDropdowns[1] as HTMLSelectElement;

            this.selectedMonth = parseInt(monthDropdown.value, 10);
            this.selectedYear = parseInt(yearDropdown.value, 10);
        }
    }

    // Check if a date belongs to the currently selected month/year
    isInCurrentMonth(date: NgbDateStruct): boolean {
        return date.month === this.selectedMonth && date.year === this.selectedYear;
    }
}
