export const API_JOB = {
    GET_JOB_BY_ID: 'api/v1/job/',
    GET_JOB_LIST: 'api/v1/job',
    GET_JOB_SELECTED_LIST: 'job/job-select-list',
    GET_JOB__SELECTED_LIST: 'license-type/dropdown',
    POST_JOB: 'api/v1/job',
    DELETE_JOB: 'api/v1/job',
    GET_JOB_DETAIL_BY_ID: 'api/v1/job/',
    UPDATE_JOB_STATUS: 'api/v1/job/',
    GET_LICENSE_TYPE_LIST: 'license-type/all',
    GET_JOB_SELECTED_LIST_BY_HOSPITAL: 'license-type/dropdown-by-hospital',
    GET_JOB_SELECTED_LIST_LICENSE_TYPE: 'job/job-select-list-by-license-type',
    GET_JOB_BY_UNIT_SELECTED_LIST: 'job/get-job-by-unit-select-list',
    GET_LICENSE_TYPE_BY_ID: 'license-type/',
    GET_LICENSE_TYPE: 'license-type',
    POST_LICENSE_TYPE: 'license-type',
    PUT_LICENSE_TYPE: 'license-type/',
    DELETE_LICENSE_TYPEE: 'license-type',
    ACTIVE_DEACTIVE_LICENSE_TYPE: 'license-type/active-deactivate/',
    GET_JOB_SPECIALITIES_SELECTED_LIST: 'specialities/dropdown',
    GET_JOB_SPECIALITIES: 'specialities',
    GET_JOB_SPECIALITIES_LIST: 'specialities/all',
    POST_JOB_SPECIALITIES: 'specialities',
    PUT_JOB_SPECIALITIES: 'specialities/',
    DELETE_JOB_SPECIALITIES: 'specialities',
    ACTIVE_DEACTIVE_SPECIALITIES: 'specialities/active-deactivate/',
    GET_JOB_TEMPLATE_SELECTED_LIST: 'template/job-dropdown',
    GET_JOB_TEMPLATE_BY_ID: 'template/',
    POST_TEMPLATE: 'template',
    PUT_TEMPLATE: 'template/',
    GET_JOB_OPENING_BY_ID: 'job-opening/get-job-opening-by-id/',
    GET_APPLICANT_LIST_BY_JOBOPENINGID: 'job-opening/list-of-job-applicants-by-jobOpeningId/',
    POST_JOB_OPENING: 'job-opening',
    PUT_JOB_OPENING: 'job-opening/',
    DELETE_JOB_OPENING: 'job-opening',
    GET_APPLICANTDTL_BY_CLINICIAN: 'job-opening/applicant-details-by-clinician/',
    UPDATE_APPLICAT_STATUS: 'job-applicant/update-app0licant-status/',
    POST_CLINICIAN_JOB_HISTORY: 'job-applicant/post-clinician-job-history',
    GET_ORIENTATION_CLINICIAN_BY_HOSPITAL: 'job-applicant/get-orientation-clinician-by-hospital/',
    PUT_ORIENTATION_CLINICION_INFO: 'job-applicant/update-orientation-clinician-info',
    BOOKMARK_JOB: 'job/bookmark-unit-jobs',
    TEMPLATE_lIST: 'template',
    DELETE_TEMPLATE: 'template',
    ACTIVATE_TEMPLATE: 'template/',
    DEACTIVATE_TEMPLATE: 'template/',
    INHERITE_TEMPLATE: 'template/inherit',
    GET_ASSESTS: 'api/user/MarketLeftPanel',
    PUT_JOB: 'api/v1/job/',
    GET_JOB_PIPELINE: 'api/v1/job/PipeLineClinician',
    GET_JOB_DOCUMENTATION: 'clinician/ClinicianDocument',
    GET_CLINICIAN_BY_ID: 'clinician/',
    GET_INTERVIEW_BY_DATE: 'api/v1/interview/JobInterviews',
    GET_CALENDAR_LIST: 'api/v1/interview/calendar',
    GET_GRADUATION_LIST: 'graduation-type/graduation-type-select-list',

    // Job - Sreening Menu
    GET_JOB_SCREENING_LIST: 'api/v1/job/ClinicianJobScreening',
    GET_JOB_TITLE_SELECT_LIST: 'api/v1/job/DropdownJob',
    GET_JOB_NURSE_SELECT_LIST: 'api/v1/job/DropdownNurse',
    GET_JOB_SCREENING_ITEM_SELECT_LIST: 'api/v1/job/DropdownScreeningItem',
    GET_JOB_INTERVIEW_LIST: 'api/v1/interview',
    GET_JOB_UNFULFILLED: 'api/v1/job/UnfulfilledClinicians',
    GET_JOB_HIRED: 'api/v1/job/HiredClinicians',
    GET_JOB_DOCUMENT_LIST: 'api/v1/job/ClinicianJobDocumentation',
    GET_JOB_TITLE_DROPDOWN_LIST: 'api/v1/job/DropdownJob',
    GET_CLINICIAN_DROPDOWN_LIST: 'api/v1/job/DropdownNurse',
    GET_DOCUMENT_DROPDOWN_LIST: 'api/v1/job/DropdownDocument',
    GET_SHIFT_TEMPLATE_SELECTED_LIST: 'template/ShiftTemplateDropdown',
    GET_COMPACT_LICENSE_STATE_LIST: 'states/CompactLicenseStateList/',
    GET_JOB_PREBOARDING: 'api/v1/job/PreboardingClinician',
    GET_JOB_IMMEDIATE: 'api/v1/job/ImmediateHiringClinicians',
    GET_JOB_RENEWAL_LIST: 'api/v1/job/DocumentRenewalList',
    GET_INTERVIEW_COUNT: 'api/v1/interview/InterviewCount',
    GET_SCREENING_COUNT: 'api/v1/job/ScreeningStagesCounts',
    GET_CANDIDATE_COUNT: 'api/v1/job/CandidateStageCount',
    GET_JOB_CANDIDATE_LIST: 'api/v1/job/Candidates',
    REJECT_JOB_APPLICANT: 'api/v1/job/RejectJobApplicant',
};

export const API_SHIFT = {
    POST_SINGLE_SHIFT: 'api/v1/shift/Single',
    POST_BULK_SHIFT: 'api/v1/shift/Bulk',
    PUT_SHIFT: 'shift/',
    DELETE_SHIFT: 'shift',
    GET_SHIFT_BY_ID: 'api/v1/shift/',
    GET_SINGLE_SHIFT_DETAIL_BY_ID: 'api/v1/shift/SingleShiftDetail/',
    GET_SHIFTS_LIST: 'api/v1/shift/BulkList',
    GET_SHIFTS_GRID_LIST: 'shift/grid-list-of-shifts',
    GET_CLINICIAN_BY_SHIFT_LIST: 'shift/list-of-clinician-by-shift',
    GET_SHIFT_BY_HOSPITAL: 'shift/get-hospital-and-shift-by-hospital/',
    POST_INVITE_STAFF_FOR_SHIFT: 'shift/invite-staff-for-shift',
    POST_SHIFT_CLINICIAN_STATUS: 'shift/update-shift-clinician-status',
    DELETE_SHIFT_CLINCIAN: 'shift/delete-shift-clincian/',
    GET_TOP_FIVE_JOB_SELECTED_LIST: 'shift/top-five-jobs-select-list',
    POST_SHIFT_CLINICIAN_OVERTIME: 'shift/update-shift-clinician-over-time',
    GET_SHIFT_CLINICIAN_FEEDBACK_LIST: 'shift-clinician-feedback/list-of-shift-clinician-feedback',
    PUT_SHIFT_CLINICIAN_PAY_RATE_STATUS: 'shift/update-shift-clinician-pay-rate-status',
    GET_JOB_TYPE_BY_JOB_SELECTED_LIST: 'shift/license-type-by-job-select-list',
    PUT_SHIFT_ATTENDANCE_TIMESHEET: 'shift-attendance-time-sheet/',
    GET_ATTENDANCE_TIMESHEET_BY_ID: 'shift-attendance-time-sheet/get-time-sheet-by-id/',
    CANCEL_SHIFT: 'shift/cancel-shift',
    GET_SHIFT_INSPECTORS_lIST: 'api/v1/shift/Inspectors',
    GET_SHIFT_CALENDAR_LIST: 'api/v1/shift/calendar',
    GET_SHIFT_INSPECTORS_DETAILS: 'api/v1/shift/',
    GET_SHIFT_OVERTIMEINFORMATION: 'api/v1/shift/OvertimeInformation',
    GET_SHIFT_TTIMES_SLOTS: 'api/v1/shift/ShiftTimesSlots',
    GET_SHIFT_INSPECTION_OPEN_DETAILS: 'api/v1/shift/',
    GET_RELEASE_REASON_SELECT_LIST: 'api/v1/shift/ReleaseReasonDropdown',
    GET_UNSCHEDULE_REASON_SELECT_LIST: 'api/v1/shift/UnscheduleReasonDropdown',
    GET_SHIFT_POSITION_BY_ID: 'Positions',
    GET_SHIFT_POSITION_HISTORY_BY_ID: '/PositionsHistory',
    GET_SHIFT_FEEDBACK: 'api/v1/shiftFeedback',
    ADD_SHIFT_POSITION: '/AddPosition',
    REMOVE_SHIFT_POSITION: 'CancelShift',
    RESCHEDULE_SHIFT_POSITION: '/RescheduleOpenShift',
    RESCHEDULE_WITH_PRICE_BONUS_SHIFT_POSITION: '/RescheduleShiftPositionWithPriceNBonus',
    UPDATE_PRICE_BONUS_POSITION: '/UpdateShiftPositionPriceNBonus',
    NCNS_RESCHEDULE_SHIFT_POSITION: '/NCNSAndRescheduleShift',
    NCNS_SHIFT_POSITION: '/NCNS',
    RELEASE_RESCHEDULE_SHIFT_POSITION: '/ReleaseAndRescheduleShift',
    RELEASE_CANCEL_SHIFT_POSITION: '/ReleaseAndCancelShift',
    UNSCHEDULE_SHIFT_POSITION: '/UnscheduleShift',
    EXTEND_SHIFT_POSITION: '/ExtenShiftTime',
    MISSING_CLOCK_IN: 'api/v1/shiftAttendanceHistory/ClockIn',
    MISSING_CLOCK_OUT: 'api/v1/shiftAttendanceHistory/ClockOut',
    DECLINE_EXCUSE: 'DeclineExcuse',
    GET_TIMESHEET_DETAILS_BY_ID: 'api/v1/shiftAttendanceTimesheet/',
    PUT_TIMESHIFT_STATUS: 'api/v1/shiftAttendanceTimesheet/',
    GET_AVAILABLE_NURSE: 'AvailableNurses',
    WITHDRAW_INVITE: 'api/v1/shift/WithdrawInvite',
    POST_SHIFT_LIST: 'api/v1/shift/List',
    POST_ADDSHIFT_SAVEDSEARCH: 'api/v1/shift/AddShiftSearchCriteria',
    POST_SAVEDSEARCH: 'api/v1/shift/ShiftSearchedCriteria',
    DELETE_SHIFT_SAVEDSEARCH: 'api/v1/shift/DeleteShiftSearchedCriteria',
    PUT_UPDATE_SEARCH_CRITERIA: 'api/v1/shift/UpdateShiftSearchCriteria',
    UPDATE_SHIFT_NOTES: 'api/v1/shift/UpdateShiftNotes',
    GET_SHIFT_AVAILABLE_NURSE: 'api/v1/shift/ShiftAvailableNurseDropdown',
    GET_SHIFT_STATUS_COUNT: 'api/v1/shift/ShiftStatusCounts',
    GET_SHIFT_COUNT: 'api/v1/shift/GroupCalendarCounts',
};

export const API_HOSPITAL = {
    POST_HOSPITAL: 'hospitals',
    PUT_HOSPITAL: 'hospitals/',
    DELETE_HOSPITAL: 'hospitals',
    GET_LIST_OF_HOSPITAL: 'hospitals/list-of-hospital',
    GET_HOSPITAL_BY_ID: 'hospitals/get-hospital-by-id/',
    GET_HOSPITAL_LIST: 'hospitals/hospital-select-list',
    GET_HOSPITAL_LIST_FOR_JOB: 'hospitals/JobHospital',
    POST_HEALTHCARE_LIST: 'Companies/Companies',
    POST_HEALTHCARE_LIST_FOR_JOB: 'Companies/JobCompanies',
    POST_HOSPITAL_CONTACT: 'hospitals/add-update-hospital-contact',
    POST_HOSPITAL_ADMIN_CONTACT: 'hospitals/add-update-hospital-admin-contact',
    POST_HOSPITAL_SHIFT_TYPE: 'hospital-shift-type',
    PUT_HOSPITAL_SHIFT_TYPE: 'hospital-shift-type/',
    DELETE_HOSPITAL_SHIFT_TYPE: 'hospital-shift-type',
    GET_HOSPITAL_SHIFT_TYPE: 'hospital-shift-type/',
    GET_HOSPITAL_SHIFT_TYPE_SELECTED_LIST: 'hospital-shift-type/select-list/',
};

export const API_CREDENTIAL = {
    POST_CREDENTIAL_TEM: 'credential-template',
    GET_CREDENTIAL_TEM_SELECTED_LIST: 'credential-template/credential-template-select-list',
    GET_CREDENTIAL_TEM_BY_ID: 'credential-template/get-credential-template-by-id/',
    GET_CREDENTIAL_TYPE_SELECTED_LIST: 'credential-type/dropdown',
    GET_CREDENTIAL_TYPE_LIST: 'credential-type/all',
    POST_CREDENTIAL_TYPE: 'credential-type',
    PUT_CREDENTIAL_TYPE: 'credential-type/',
    DELETE_CREDENTIAL_TYPE: 'credential-type',
    GET_CREDENTIAL_CATEGORY_LIST: 'credential-category/all',
    POST_CREDENTIAL_CATEGORY: 'credential-category',
    PUT_CREDENTIAL_CATEGORY: 'credential-category/',
    DELETE_CREDENTIAL_CATEGORY: 'credential-category',
    GET_CREDENTIAL_CATEGORY_SELECTED_LIST: 'credential-category/dropdown',
    ACTIVE_DEACTIVE_CREDENTIAL: 'credential-type/active-deactivate/',
    ACTIVE_DEACTIVE_CREDENTIAL_CATEGORY: 'credential-category/active-deactivate/',
    POST_CUSTOM_CREDENTIAL_TYPE: 'custom-credential-type',
    PUT_CUSTOM_CREDENTIAL_TYPE: 'custom-credential-type/',
    DELETE_CUSTOM_CREDENTIAL_TYPE: 'custom-credential-type',
    GET_CUSTOM_CREDENTIAL_TYPE: 'custom-credential-type/all',
    ACTIVE_DEACTIVE_CUSTOM_CREDENTIAL_TYPE: 'custom-credential-type/active-deactivate/',
    GET_CATEGORY_AND_TYPE: 'credential-category/get-credential-category-and-type-dropdown',
    GET_CREDENTIAL_CATEGORY_FOR_THRIVE: 'credential-category/ThriveAdminCategory',
    GET_CREDENTIAL_TYPE_FOR_THRIVE: 'credential-type/ThriveAdminType/',
    ADD_NURSE_CREDENTIAL: 'clinician/credential',
    GET_CREDENTIAL_STATES: 'credential-type/States',
};

export const API_CLINICIAN = {
    BASE_URL_CLINICIAN: 'clinician',
    POST_SCHEDULE_MEETING: 'clinician/schedule-meeting',
    PUT_CREDENTIAL_APPROVE_REJECT: 'clinician/credential-approve-reject/',
    GET_SCHEDULE_MEETING_BY_CLINICIANID: 'clinician/schedule-meeting/get-schedule-meeting-by-clinicianId/',
    GET_MEETING_ATTENDEES_SELECT_LIST: 'clinician/schedule-meeting/attendees-select-list',
    PUT_SCHEDULE_MEETING_FEEDBACK: 'clinician/schedule-meeting/update-feedback/',
    GET_SELECTED_LIST_BY_HOSPITAL: 'clinician/clinician-select-list-by-hospital/',
    GET_CLINICIAN_BY_ID: 'clinician/get-clinician-by-id/',
    GET_CLINICIAN_BY_HOSPITAL: 'clinician/clinician-by-hospital/',
    GET_DISPUTE_SELECTED_LIST: 'clinician-dispute-time-sheet/select-list',
    GET_DISPUTE_LIST: 'clinician-dispute-time-sheet/list-of-clinician-dispute-time-sheet',
    POST_DISPUTE_TIME_SHEET: 'clinician-dispute-time-sheet',
    PUT_DISPUTE_TIME_SHEET: 'clinician-dispute-time-sheet/',
    DELETE_DISPUTE: 'clinician-dispute-time-sheet/',
    ACTIVE_DEACTIVE_DISPUTE: 'clinician-dispute-time-sheet/active-deactivate/',
    ALL_ACTIVE_NURSE_LIST: 'clinician/FacilityActiveNurses',
    REGISTERED_CLINICIANS: 'clinician/RegisteredClinicians',
    NURSE_STATE_SELECT_LIST: 'clinician/FacilityNurseState',
    NURSE_CITY_SELECT_LIST: 'clinician/FacilityNurseCity',
    GET_NURSE_JOB_LIST: 'api/v1/job/NurseJobs',
    GET_NURSE_SHIFT: 'api/v1/shift/NurseShifts',
    GET_NURSE_TIMESHEETS: 'api/v1/shiftAttendanceTimesheet/NurseTimeSheets',
    ALL_RESTRICTED_NURSE_LIST: 'clinician/FacilityRestrictedNurses',
    GET_RESTRICT_REASON_SELECT_LIST: 'clinician/RestrictReason',
    GET_NURSE_FACILITY_SELECT_LIST: '/NurseFacilities',
    RESTRICT_NURSE: 'clinician/RestrictNurse',
    SHIFT_HISTORY: 'clinician/ShiftHistory',
    GET_DISPUTE_TIMESHEET_REASON: 'clinician-dispute-time-sheet/Reason',
    GET_NURSE_HEALTHCARE_SELECT_LIST: '/NurseHealthcares',
    GET_HIRED_CLINICIAN_HOSPITAL: 'hospitals/clinicianHiredHospital',
    POST_AUTHORSELECTLIST: 'note/AuthorSelectList',
    GET_NOTELIST: 'note/List',
    POST_NOTES: 'note',
    GET_NOTE: 'note/',
    UPDATE_RESTRICTED_NURSE: 'clinician/UpdateClinicianRestriction',
    POST_RESTICTIONHISTORIES: 'clinician/RestrictionHistories',
    POST_RESTRICTIONDETAILS: '/RestrictionDetail',
    GET_RESTRICTEDFACILITY: 'clinician/RestrictedFacilities',
    GET_NURSE_RESTRICTION_DETAILS: '/FacilityRestrictedNurseById',
    POST_CLINICIAN_DOCUMENTS: 'clinician/Documents',
    GET_NURSE_SELECT_LIST: 'clinician/NurseDocuments',
    GET_DOCUMENT_TITLE_SELECT_LIST: 'clinician/DocumentsTitle',
    GET_INACTIVE_REASON_LIST: 'clinician/InactiveReason',
    POST_INACTIVE_NURSE: 'clinician/InactiveNurse',
    POST_ACTIVE_NURSE: 'clinician/ActiveNurse',
    GET_INACTIVE_HOSPITAL: 'clinician/InactiveClinicianHospitals',
    ALL_FORMER_NURSE_LIST: 'clinician/AccountDeletedNurse',
    GET_ATTENTION_RECORDS_LIST: 'clinician/AttentionRecords',
    GET_TERMINATE_REASON_LIST: 'clinician/TerminateReason',
    POST_TERMINATE_NURSE: 'clinician/TerminateNurse',
    GET_NURSE_STATUS: 'clinician/CheckExistence',
    POST_ONBOARD_NURSE: 'clinician/Onboard',
    GET_ONBOARD_NURSE_DETAIL: 'clinician/OnboardDetails',
    GET_HOSPITAL_LIST: 'api/v1/job/FacilityJobs',
    ONBOARD_NURSE_LEVEL: 'clinician/UpdateOnboardNurseLevel',
};

export const API_CONTACTS = {
    POST_CONTACT: 'Contacts',
    PUT_CONTACT: 'Contacts/',
    DELETE_CONTACT: 'Contacts',
    GET_CONTACT_BY_ID: 'Contacts/get-contacts-by-id/',
    GET_CONTACTS_SELECTED_LIST: 'Contacts/contacts-select-list',
    GET_CONTACTS_LIST: 'Contacts/list-of-contacts',
    POST_COMPANY_CONTACT: 'Contacts/company-contacts',
    PUT_COMPANY_CONTACT: 'Contacts/company-contacts',
    POST_ADMIN_COMPANY_CONTACT: 'Contacts/admin-company-contacts',
    PUT_ADMIN_COMPANY_CONTACT: 'Contacts/admin-company-contacts',
    GET_FACILITY_CONTACT_LIST: 'Contacts/facility-contact-list',
    GET_OTHER_CONTACT_LIST: 'Contacts/other-contact-list',
    GET_HEALTHCARE_CONTACT_LIST: 'Contacts/healthcare-contacts',
    ACTIVE_DEACTIVE_CONTACT: 'Contacts/active-Inactive-contact',
};

export const API_COMPANIES = {
    GET_COMPANY_LIST: 'Companies/list-of-companies',
    POST_COMPANY: 'Companies',
    PUT_COMPANY: 'Companies/',
    DELETE_COMPANY: 'Companies',
    GET_COMPANY_BY_ID: 'Companies/get-company-by-id/',
    GET_COMPAYIES_SELECTED_LIST: 'Companies/company-select-list',
    ACTIVE_DEACTIVE_COMPANY: 'Companies/active-inactive-company',
    HEALTHCARE_PANEL_COMPANY: 'Companies/healthcare-penal',
    POST_POLICY: 'policies',
    PUT_POLICY: 'policies',
    GET_POLICY_BY_ID: 'policies',
    POST_PERSON_CONTACT: 'Contacts/contact-type',
    PUT_PERSON_CONTACT: 'Contacts/contact-type/',
    GET_HEALTHCARE_USER: 'Contacts/dropdown',
    GET_THRIVE_HEALTH_USER: 'Contacts/ThriveUserDropDown',
    GET_USER_TYPE: 'Contacts/contact-type',
    POST_BREAK_TIME: 'api/v1/break-time',
    PUT_BREAK_TIME: 'api/v1/break-time',
    INHERIT_BREAKTIME: 'api/v1/break-time/inherit',
    GET_BREAK_TIME: 'api/v1/break-time',
    INHERIT_POLICIES: 'policies/inherit',
    GET_SELECTED_TOGGLE: 'hospitals/',
    GET_THRIVEHEALTH_USER: 'Contacts/ThriveUserDropDown',
    GET_BREAK_TIME_SELECT_LIST: 'api/v1/break-time/dropdown',
};

export const API_UNITS = {
    POST_UNIT: 'units',
    PUT_UNIT: 'units/',
    DELETE_UNIT: 'units',
    GET_UNIT_BY_ID: 'units/get-unit-by-id/',
    GET_UNITS_SELECTED_LIST: 'units/unit-select-list',
    GET_Facility_UNITS_SELECTED_LIST: 'units/FacilityUnits',
};

export const API_REGION = {
    GET_COUNTRIES_LIST: 'countries/list-of-countries',
    GET_STATES_LIST: 'states/list-of-states',
    GET_STATES_SELECTED_LIST: 'states/state-select-list',
    POST_STATES: 'states',
    PUT_STATES: 'states/',
    DELETE_STATE: 'states/',
    ACTIVE_DEACTIVE_STATE: 'states/active-deactivate/',
    GET_CITIES: 'cities/',
    GET_CITIES_SELECTED_LIST: 'cities/city-select-list/',
    POST_CITIES: 'cities',
    PUT_CITIES: 'cities/',
    DELETE_CITIES: 'cities/',
    ACTIVE_DEACTIVE_CITY: 'cities/active-deactivate/',
    GET_COUNTIES: 'counties/all',
    POST_COUNTIES: 'counties',
    PUT_COUNTIES: 'counties/',
    DELETE_COUNTIES: 'counties',
    ACTIVE_DEACTIVE_COUNTIES: 'counties/active-deactivate/',
    GET_STATES_ACRONYM_SELECTED_LIST: 'states/dropdown',
    GET_TIMEZONE_LIST: 'api/v1/timezone',
    GET_STATE: 'states/',
};

export const API_AUTH = {
    POST_AUTHENTICATE: 'api/user/authenticate',
    POST_FORGOT_PASSWORD: 'api/user/forgot-password',
    POST_RESET_PASSWORD: 'api/user/reset-password',
    POST_CHANGE_PASSWORD: 'api/user/change-password',
    PUT_EMAIL_CONFIRMATION: 'api/user/email-confirmation',
    GET_USER_SELECTED_LIST: 'user/user-select-list',
    GET_USERS: 'api/user/get',
    POST_CONTACT_ADMIN: 'api/user/create-contact-admin',
    POST_USER_PASSWORD_VALIDATION: 'api/user/password-validation',
    GET_ROLE_LIST: 'roles',
    POST_ROLES: 'roles',
    PUT_ROLES: 'roles',
    DELETE_ROLES: 'roles',
    GET_ROLES_LIST: 'roles/role-select-list',
    GET_ROLES_SELECTED_LIST: 'roles/all-role-select-list',
    GET_ROLES_BY_USER_ROLEID: 'roles/role-select-list-by-user-roleId',
    GET_ROLE_BASE_ASSETS_LIST: 'assets/list-of-role-base-assets',
    GET_ROLE_SELECT_LIST: 'roles/default-role-select-list',
    ACTIVE_DEACTIVE_ROLE: 'roles/active-deactivate/',
    POST_ROLE_BASE_ASSETS: 'assets/add-update-role-base-assets',
    GET_ASSETS_LIST: 'assets/list-of-assets',
    POST_REFRESH_TOKEN: 'users/refresh-token',
    GET_ALL_PASSWORD_RULES: 'password-rules/get-all-password-rules',
    POST_COMPANY_PASSWORD_RULE: 'password-rules/add-company-password-rules',
    GET_CUSTOM_ROLE_LIST: 'roles/CompanyRole',
};

export const API_ASSESTS = {
    GET_ASSETS_LIST_BY_ROLEID: 'assets/list-of-assets',
    GET_ASSETS_LIST_ROLEID: 'assets/all-asset-menus',
    POST_ASSETS: 'assets/add-site-map-menu',
    GET_ASSETS_LIST: 'assets/dropdown',
    PUT_ASSETS: 'assets/update-site-map-menu',
    DELETE_ASSETS: 'assets/delete-site-map-menu',
};

export const API_GRADUATION_TYPE = {
    POST_GRADUATION_TYPE: 'graduation-type',
    PUT_GRADUATION_TYPE: 'graduation-type/',
    DELETE_GRADUATION_TYPE: 'graduation-type/',
    GET_GRADUATION_TYPE: 'graduation-type/graduation-type-list-detail',
    ACTIVE_DEACTIVE_GRADUATION_TYPE: 'graduation-type/active-deactivate/',
};
export const API_UNIT_TYPE = {
    POST_UNIT_TYPE: 'unit-type',
    PUT_UNIT_TYPE: 'unit-type/',
    DELETE_UNIT_TYPE: 'unit-type',
    GET_UNIT_TYPE: 'unit-type/all',
    ACTIVE_DEACTIVE_UNIT_TYPE: 'unit-type/active-deactivate/',
    GET_UNIT_TYPE_SELECT_LIST: 'unit-type/dropdown',
    GET_LOWER_LEVEL_UNIT_TYPE_SELECT_LIST: 'unit-type/lower-level-unit-types-dropdown',
};
export const API_POLICY = {
    GET_POLICY_TYPE_OF_LIST: 'policy-types/list-of-policy-types',
    GET_POLICY_TYPE_LIST: 'policy-types/policy-types-list',
    POST_POLICY_TYPE: 'policy-types',
    PUT_POLICY_TYPE: 'policy-types/',
    DELETE_POLICY_TYPE: 'policy-types',
};

export const API_HOLIDAYS = {
    GET_PUBLIC_HOLIDAY_LIST: 'public-holidays/all-public-holiday',
    GET_CUSTOM_HOLIDAY_LIST: 'public-holidays/all-custom-holiday',

    POST_PUBLIC_HOLIDAY: 'public-holidays/public-holiday',
    PUT_PUBLIC_HOLIDAY: 'public-holidays/',
    DELETE_PUBLIC_HOLIDAY: 'public-holidays',
    ACTIVE_DEACTIVE_PUBLIC_HOLIDAY: 'public-holidays/active-deactivate/',
    POST_CUSTOM_PUBLIC_HOLIDAY: 'public-holidays/custom-holiday',
};

export const API_ADDRESS = {
    POST_ADDRESS: 'address',
    PUT_ADDRESS: 'address/',
    DELETE_ADDRESS: 'address/',
    GET_ADDRESS_LIST: 'address/list-of-addresss',
    ACTIVE_DEACTIVE_ADDRESS: 'address/active-deactivate/',
};

export const API_FEEDBACK = {
    POST_FEEDBACK_FORM_INFO: 'feedback-form-info',
    GET_FEEDBACK_FORM_INFO_LIST: 'feedback-form-info',
    PUT_FEEDBACK_FORM_INFO: 'feedback-form-info/',
    DELETE_FEEDBACK_FORM_INFO: 'feedback-form-info/',
    ACTIVE_DEACTIVE_FEEDBACK: 'feedback-form-info/active-deactivate/',
    POST_FEEDBACK: 'applicant-reference/feedbcak/add-update-applicant-reference-feedback',
    POST_FEEDBACK_SEND_EMAIL: 'applicant-reference/feedbcak/send-feedback-email',
    GET_REFERENCE_BY_ID: 'applicant-reference/feedbcak/get-applicant-reference-by-applicantReferenceId/',
    GET_REFERENCE_FEEDBACK_BY_ID:
        'applicant-reference/feedbcak/get-applicant-reference-feedback-by-applicantReferenceId/',
};

export const API_AUDITLOG = {
    GET_AUDITLOG_BY_ID: 'audit-log/get-audit-log-by-id',
};

export const API_EXPORT_IMPORT = {
    GET_EXPORT_HOSPITAL: 'excel-import/export-hospital/',
    GET_EXPORT_UNIT: 'excel-import/export-unit',
    POST_IMPORT_HOSPITAL: 'excel-import/import-hospital',
    POST_IMPORT_UNIT: 'excel-import/import-unit',
    POST_HOSPITAL: 'excel-import/post-hospital',
    POST_UNIT: 'excel-import/post-unit',
    PUT_HOSPITAL: 'excel-import/put-hospital/',
    PUT_UNIT: 'excel-import/put-unit/',
    DELETE_HOSPITAL: 'excel-import/delete-hospital',
    DELETE_UNIT: 'excel-import/delete-unit',
    POST_MEDIA_UALOAD: 'media/upload',
    POST_IMPORT_COUNTY: 'BulkUpload/County',
    POST_IMPORT_REGION: 'BulkUpload/Region',
    POST_IMPORT_HEALTHCARE: 'BulkUpload/Healthcare',
    POST_IMPORT_FACILITY: 'BulkUpload/Facility',
    POST_IMPORT_JOB: 'BulkUpload/Job',
    POST_IMPORT_NURSE: 'BulkUpload/Nurse',
};

export const API_CHANGE_REQUEST = {
    POST_CHANGE_REQUEST: 'change-request',
    PUT_CHANGE_REQUEST: 'change-request/',
    DELETE_CHANGE_REQUEST: 'change-request',
};

export const API_CHAT = {
    POST_SEND_MSG_MOBILE: 'chat/send-message-mobile',
    GET_ALL_CONTACT_LIST: 'chat/all-contacts-list',
    GET_RECENT_CONTACT_LIST: 'chat/recent-contacts-list',
    GET_LIST_CONVERSATION_HISOTRY: 'chat/list-of-conversations-hisotry',
    PUT_CHAT_STATUS: 'chat/update-chat-status',
    GET_CHAT_LIST: 'chat/Conversations',
    POST_CHAT: 'chat/SendMessage',
    GET_CHAT_DETAILS: 'chat/Detail',
    UPDATE_CHAT: 'chat/UpdateChatStatus',
    PUT_UPDATENOTIFICATION_READSTATUS: 'Notifications/UpdateNotificationReadStatus',
    PUT_UPDATENOTIFICATION_DELETESTATUS: 'Notifications/UpdateNotificationDeleteStatus',
};

export const API_RATECARD = {
    GET_RATECARD_TEM_SELECTED_LIST: 'rate-card-template/rate-card-template-select-list',
    GET_BACKGROUNGCHECK_TYPE_SELECTED_LIST: 'background-check-type/dropdown',
    POST_RATECARD_TEM: 'rate-card-template',
    GET_RATECARD_TEM_BY_ID: 'rate-card-template/get-rate-card-template-by-id/',
    GET_RATECARD_TEM_LIST: 'rate-card-template/list-of-rate-card-template',
};

export const API_NOTICE_BOARD = {
    POST_NOTICE_BOARD: 'notice-board',
    PUT_NOTICE_BOARD: 'notice-board/',
    DELETE_NOTICE_BOARD: 'notice-board',
};

export const API_TIMEZONE = {
    POST_TIMEZONE: 'timezone',
    PUT_TIMEZONE: 'timezone/',
    DELETE_TIMEZONE: 'timezone',
    GET_TIMEZONE_SELECTED_LIST: 'timezone/time-zone-select-list',
};
export const BACKGROUND_CHECK = {
    POST_BACKGROUND_CHECK: 'background-check-type',
    PUT_BACKGROUND_CHECK: 'background-check-type',
    DELETE_BACKGROUND_CHECK: 'background-check-type',
    GET_BACKGROUND_CHECK: 'background-check-type/all',
    ACTIVE_DEACTIVE_BACKGROUND_CHECK: 'background-check-type/active-deactivate/',
};
export const API_MARKET = {
    GET_MARKET: 'market/all',
    POST_MARKET: 'market',
    PUT_MARKET: 'market',
    DELETE_MARKET: 'market',
    ACTIVE_DEACTIVE_MARKET: 'market/active-deactivate/',
    GET_COUNTIES: 'counties/dropdown/',
    GET_THRIVE_USER: 'api/user/market-level-user-dropdown',
    GET_MARKET_SELECTED_LIST: 'market/dropdown',
    GET_COUNTY_LIST: 'counties/market',
    GET_MARKET_LIST: 'market/HospitalMarkets',
};
export const CREDENTIAL_SET = {
    POST_CREDENTIAL_SET: 'background-check-type',
    PUT_CREDENTIAL_SET: 'background-check-type',
    DELETE_CREDENTIAL_SET: 'background-check-type',
    GET_CREDENTIAL_SET: 'background-check-type/all',
    ACTIVE_DEACTIVE_CREDENTIAL_SET: 'background-check-type/active-deactivate/',
};

export const API_NOTIFICATION = {
    GET_NOTIFICATIONS: 'Notifications/WebNotification',
    PUT_NOTIFICATIONS: 'Notifications/update-notification-status/',
    UPDATE_NOTIFICATION_READ_STATUS: 'Notifications/UpdateNotificationReadStatus',
    DELETE_NOTIFICATION: 'Notifications/UpdateNotificationDeleteStatus',
    GET_NOTIFICATION_COUNT: 'Notifications/NotificationCount',
};

export const API_GOOGLEMEET_LINK_GENERATE = {
    GOOGLE_AUTH_REDIRECT_URI: 'google-meet-link/OauthRedirect',
};
export const API_CREDENTIAL_SET = {
    POST_CREDENTIAL_SET: 'credential-set/',
    GET_CREDENTIAL_SET: 'credential-set/all',
    DELETE_CREDENTIAL_SET: 'credential-set',
    ACTIVE_DEACTIVE_CREDENTIAL_SET: 'credential-set/active-deactivate/',
    INHERIT_CREDENTIAL_SET: 'credential-set/inherit',
    SELECT_LIST_CREDENTIAL_SET: 'credential-set/dropdown',
    GET_CREDENTIAL_SET_BY_ID: 'credential-set/',
};
export const API_INSTRUCTIONS = {
    POST_INSTRUCTIONS: 'api/v1/instructions/',
    GET_INSTRUCTIONS: 'api/v1/instructions',
    DELETE_INSTRUCTIONS: 'api/v1/instructions',
    ACTIVE_DEACTIVE_INSTRUCTIONS: 'api/v1/instructions/active-deactivate/',
    PUT_INSTRUCTIONS: 'api/v1/instructions/',
    GET_INSTRUCTION_SELECT_LIST: 'api/v1/instructions/dropdown',
    GET_INSTRUCTION_DETAILS_BY_ID: 'api/v1/instructions/',
    GET_SHIFT_INSTRUCTION_SELECT_LIST: 'api/v1/instructions/shift/dropdown',
};
export const API_AUDITTRAIL = {
    POST_AUDITLOG: 'Auditlog/All',
    POST_AUDITLOG_CONTACT: 'AuditLog/Contacts',
};
export const API_PRICES = {
    POST_PRICES: 'api/v1/pricerate',
    GET_PRICES: 'api/v1/pricerate',
    DELETE_PRICES: 'api/v1/pricerate',
    // ACTIVE_DEACTIVE_PRICES: 'api/v1/pricerate/active-deactivate/',
    PUT_PRICES: 'api/v1/pricerate/',
    // GET_PRICES_SELECT_LIST: 'api/v1/pricerate/dropdown',
    GET_PRICES_LIST: 'api/v1/pricerate/hospital/',
};
export const API_SHIFT_TIME = {
    POST_SHIFT_TIME: 'api/v1/shift-times',
    GET_SHIFT_TIME: 'api/v1/shift-times',
    DELETE_SHIFT_TIME: 'api/v1/shift-times',
    ACTIVE_DEACTIVE_SHIFT_TIME: 'api/v1/shift-times/active-deactivate/',
    PUT_SHIFT_TIME: 'api/v1/shift-times/',
    TEMPLATE_SHIFT_TIME_SELECT_LIST: 'template/shifttimes',
    TEMPLATE_PREFERRED_SHIFT_TIME: 'template',
};

export const API_JOB_PROFILE_SUMMARY = {
    GET_LIST_CLICNICIAN_LICENSE_LIST: 'api/v1/job/ClinicianLicenses',
    GET_CLINICIAN_LICENSE_DETAILS_BY_ID: 'clinician-license/',
    UPDATE_CLINICIAN_LICENSE_STATUS: 'api/v1/job/UpdateClinicianLicenseStatus',
    PUT_INTERVIEW: 'api/v1/interview/',
    GET_CLINICIAN_INTERVIEW_DETAIL: 'api/v1/interview/ClinicianInterview',
    GET_LIST_CLICNICIAN_EDUCATION_LIST: 'api/v1/job/ClinicianEducations',
    GET_CLINICIAN_EDUCATION_DETAILS_BY_ID: 'clinician-education/',
    GET_LIST_CLICNICIAN_BACKGROUND_CHECK_LIST: 'api/v1/job/ClinicianBackgroundChecks',
    GET_CLINICIAN_BACKGROUND_CHECK_DETAILS_BY_ID: 'api/v1/job-clinician-background-check/',
    GET_LIST_CLICNICIAN_EXPERIENCE_LIST: 'api/v1/job/ClinicianExperiences',
    GET_CLINICIAN_EXPERIENCE_DETAILS_BY_ID: 'clinician/experience/',
    GET_LIST_CLICNICIAN_CREDENTIAL_LIST: 'api/v1/job/ClinicianCredentials',
    GET_CLINICIAN_CREDENTIAL_DETAILS_BY_ID: 'clinician/credential/',
    UPDATE_CLINICIAN_EDUCATION_STATUS: 'api/v1/job/UpdateClinicianEducationStatus',
    UPDATE_CLINICIAN_BACKGROUND_CHECK_STATUS: 'api/v1/job/UpdateClinicianBackgroundCheckStatus',

    UPDATE_CLINICIAN_CREDENTIAL_STATUS: 'api/v1/job/UpdateClinicianCredentialStatus',
    UPDATE_CLINICIAN_EXPERIENCE_STATUS: 'api/v1/job/UpdateClinicianExperienceStatus',
    VERIFY_CLINICIAN_DOCUMENTATION_STATUS: 'clinician/VerifiedClinicianDocument/',
    UPDATE_CLINICIAN_DOCUMENTATION_STATUS: 'api/v1/job/UpdateClinicianDocumentStatus',
    GET_CLINICIAN_DOCUMENTATION_DETAILS_BY_ID: 'api/v1/job/ClinicianDocument/',
    UPDATE_CLINICIAN_EMPLOYMENT_FORM_STATUS: 'ClinicianEmploymentForm/',
    GET_CLINICIAN_EMPLOYMENT_FORM_DETAILS_BY_ID: 'ClinicianEmploymentForm/',
    UPDATE_CLINICIAN_LICENSE: 'clinician/UploadClinicianLicense',
    UPDATE_CLINICIAN_BACKGROUND: 'clinician/UploadClinicianBackgroundCheck',
    UPDATE_CLINICIAN_HEALTHCAREFORM: 'clinician/UploadClinicianHealthareEmploymentForm',
    UPDATE_CLINICIAN_DOCUMENT: 'clinician/UploadClinicianJobDocument',
    UPDATE_CLINICIAN_EDUCATION: 'clinician/UploadClinicianEducation',
};
export const API_TIME_SHIFT = {
    GET_LIST_TIMESHEET: 'api/v1/shiftAttendanceTimesheet/All',
};

export const API_BULK_OPERATION_SHIFT = {
    BASEURL_SHIFT_OPERATION: 'shiftoperation/',
    RESCHEDULE: 'RescheduleOpenShifts',
    RESCHEDULE_WITH_BOOST: 'RescheduleShiftPositionWithPriceNBonus',
    BOOST: 'UpdateShiftPositionPriceNBonus',
    CANCEL: 'CancelShifts',
    UNSCHEDULE: 'UnscheduleShift',
    NCNS: 'NCNS',
    NCNS_RESCHEDULE: 'NCNSAndRescheduleShift',
    RELEASE: 'ReleaseAndCancelShift',
    EXTEND_TIME: 'ExtendShiftsTime',
    WITHDRAW_INVITE: 'WithdrawInvite',
    REPUSH: 'RePush',
    MANUAL_CLOCK_IN: 'ClockIns',
    MANUAL_CLOCK_OUT: 'ClockOuts',
};

export const API_SPECIALTY_MANAGEMENT = {
    GET_MAPPED_SPECIALTY_LIST: 'facilityspeciality',
    UPDATE_SPECIALTY_PRIORITY: 'facilityspeciality/UpdateSpecialityPriorities',
    GET_SPECIALTY_DROPDOWN_LIST: 'facilityspeciality/Specialities',
    GET_SPECIALTY_FILTER_DROPDOWN_LIST: 'facilityspeciality/FilterSpecialities',
};

export const API_HEALTHCARE_EMPLOYMENT_FORM = {
    GET_API_HEALTHCARE_EMPLOYMENT_FORM_LIST: 'HealthcareEmployementForm/all',
    PUT_API_HEALTHCARE_EMPLOYMENT_FORM: 'HealthcareEmployementForm',
};
