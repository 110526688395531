<div class="modal-content chat-main-model">
    <div class="modal-header">
        <div class="modal-title h4" id="example-modal-sizes-title-lg">
            <ng-container>Chat</ng-container>
        </div>
    </div>

    <div class="profile-model-nav">
        <div class="new_nav">
            <ul ngbNav #defaultNav="ngbNav" [(activeId)]="defaultNavActiveId" class="nav-tabs">
                <li [ngbNavItem]="1" class="" (click)="onNavChange(1)">
                    <a ngbNavLink class="">All Conversations</a>
                    <ng-template ngbNavContent>
                        <div *ngIf="chatList.length == 0">
                            <div class="no-data-available">
                                <figure>
                                    <img src="assets/logo/NoListFound.png" alt="" />
                                </figure>
                                <h5>No Data Found</h5>
                            </div>
                        </div>
                        <div
                            *ngIf="chatList.length > 0"
                            #scrollable
                            class="job-profile-card-list"
                            (scroll)="onScroll()"
                        >
                            <div class="document-list" *ngFor="let item of chatList">
                                <div
                                    class="all-job-box card p-3 rounded-0 shadow-none h-100 d-flex flex-column cursor-pointer"
                                    (click)="onChatDetails(item)"
                                >
                                    <div class="job-top-box d-flex align-items-center">
                                        <!-- Center Image -->
                                        <figure class="me-3">
                                            <img
                                                *ngIf="item?.hospitalProfileUrl"
                                                src="{{ item.hospitalProfileUrl }}"
                                                alt="Center image"
                                                class="wd-60 ht-60"
                                            />
                                            <img
                                                *ngIf="!item?.hospitalProfileUrl"
                                                src="assets/images/others/company.png"
                                                class="wd-60 ht-60"
                                            />
                                        </figure>

                                        <!-- Text Information -->
                                        <div class="job-top-info flex-grow-1">
                                            <h5 class="mb-1">
                                                {{ item?.hospitalName }}
                                            </h5>
                                            <p class="mb-1" *ngIf="item?.typeName != 'General Chat'">
                                                {{ item?.typeName }} : {{ item?.licenseTypeAcronym }} -
                                                {{ item.specialityName }}
                                            </p>
                                            <p class="mb-1" *ngIf="item?.shift">
                                                {{ item.shift }} | {{ item?.date }} - {{ item?.time }}
                                            </p>
                                            <div class="d-flex align-items-center">
                                                <figure class="me-2">
                                                    <img
                                                        *ngIf="item?.clinicianProfileUrl"
                                                        src="{{ item.clinicianProfileUrl }}"
                                                        alt="User image"
                                                        class="wd-40 ht-40 rounded-circle"
                                                    />
                                                    <div *ngIf="!item?.clinicianProfileUrl" class="rounded-circle">
                                                        <span
                                                            class="d-flex justify-content-center align-items-center bg-primary fw-bolder wd-40 ht-40 rounded-circle text-white"
                                                        >
                                                            <p>
                                                                {{ item?.clinicianName?.split(' ')?.[0]?.charAt(0)








                                                                }}{{ item?.clinicianName?.split(' ')?.[1]?.charAt(0) }}
                                                            </p>
                                                        </span>
                                                    </div>
                                                </figure>
                                                <div>
                                                    <p class="mb-0">Chat with {{ item?.clinicianName }}</p>
                                                    <p
                                                        class="mt-1 mb-0 text-danger"
                                                        *ngIf="item?.unreadMessageCount > 0"
                                                    >
                                                        {{ item.unreadMessageCount }} unread message
                                                    </p>
                                                    <p
                                                        class="mt-1 mb-0 text-muted"
                                                        *ngIf="item?.unreadMessageCount == 0"
                                                    >
                                                        Last message:
                                                        {{ item?.createdOn | roleBasedTimezone : 'ordinal' }}
                                                        -
                                                        {{ item.createdOn | roleBasedTimezone : 'shortTime' }}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="ms-auto">
                                            <i class="fas fa-chevron-right"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </li>
                <li [ngbNavItem]="2" class="" (click)="onNavChange(2)">
                    <a ngbNavLink class="">Jobs Only</a>
                    <ng-template ngbNavContent>
                        <div *ngIf="chatList.length == 0">
                            <div class="no-data-available">
                                <figure>
                                    <img src="assets/logo/NoListFound.png" alt="" />
                                </figure>
                                <h5>No Data Found</h5>
                            </div>
                        </div>
                        <div
                            *ngIf="chatList.length > 0"
                            #scrollable
                            class="job-profile-card-list"
                            (scroll)="onScroll()"
                        >
                            <div class="document-list" *ngFor="let item of chatList">
                                <div
                                    class="all-job-box card p-3 rounded-0 shadow-none h-100 d-flex flex-column cursor-pointer"
                                    (click)="onChatDetails(item)"
                                >
                                    <div class="job-top-box d-flex align-items-center">
                                        <!-- Center Image -->
                                        <figure class="me-3">
                                            <img
                                                *ngIf="item?.hospitalProfileUrl"
                                                src="{{ item.hospitalProfileUrl }}"
                                                alt="Center image"
                                                class="wd-60 ht-60"
                                            />
                                            <img
                                                *ngIf="!item?.hospitalProfileUrl"
                                                src="assets/images/others/company.png"
                                                class="wd-60 ht-60"
                                            />
                                        </figure>

                                        <!-- Text Information -->
                                        <div class="job-top-info flex-grow-1">
                                            <h5 class="mb-1">
                                                {{ item?.hospitalName }}
                                            </h5>
                                            <p class="mb-1">
                                                {{ item?.typeName }} : {{ item?.licenseTypeAcronym }} -
                                                {{ item.specialityName }}
                                            </p>
                                            <p class="mb-1" *ngIf="item?.shift">
                                                {{ item.shift }} | {{ item?.date }} - {{ item?.time }}
                                            </p>
                                            <div class="d-flex align-items-center">
                                                <figure class="me-2">
                                                    <img
                                                        *ngIf="item?.clinicianProfileUrl"
                                                        src="{{ item.clinicianProfileUrl }}"
                                                        alt="User image"
                                                        class="wd-40 ht-40 rounded-circle"
                                                    />
                                                    <img
                                                        *ngIf="item?.clinicianProfileUrl == null"
                                                        src="./assets/images/others/blank.png"
                                                        class="wd-40 ht-40 rounded-circle"
                                                    />
                                                </figure>
                                                <div>
                                                    <p class="mb-0">Chat with {{ item?.clinicianName }}</p>
                                                    <p
                                                        class="mt-1 mb-0 text-danger"
                                                        *ngIf="item?.unreadMessageCount > 0"
                                                    >
                                                        {{ item.unreadMessageCount }} unread message
                                                    </p>
                                                    <p
                                                        class="mt-1 mb-0 text-muted"
                                                        *ngIf="item?.unreadMessageCount == 0 && item?.createdOn"
                                                    >
                                                        Last message:
                                                        {{ item?.createdOn | roleBasedTimezone : 'ordinal' }}
                                                        -
                                                        {{ item.createdOn | roleBasedTimezone : 'shortTime' }}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="ms-auto">
                                            <i class="fas fa-chevron-right"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </li>
                <li [ngbNavItem]="3" class="" (click)="onNavChange(3)">
                    <a ngbNavLink class="">Shift Only</a>
                    <ng-template ngbNavContent>
                        <div *ngIf="chatList.length == 0">
                            <div class="no-data-available">
                                <figure>
                                    <img src="assets/logo/NoListFound.png" alt="" />
                                </figure>
                                <h5>No Data Found</h5>
                            </div>
                        </div>
                        <div
                            *ngIf="chatList.length > 0"
                            #scrollable
                            class="job-profile-card-list"
                            (scroll)="onScroll()"
                        >
                            <div class="document-list" *ngFor="let item of chatList">
                                <div
                                    class="all-job-box card p-3 rounded-0 shadow-none h-100 d-flex flex-column cursor-pointer"
                                    (click)="onChatDetails(item)"
                                >
                                    <div class="job-top-box d-flex align-items-center">
                                        <!-- Center Image -->
                                        <figure class="me-3">
                                            <img
                                                *ngIf="item?.hospitalProfileUrl"
                                                src="{{ item.hospitalProfileUrl }}"
                                                alt="Center image"
                                                class="wd-60 ht-60"
                                            />
                                            <img
                                                *ngIf="!item?.hospitalProfileUrl"
                                                src="assets/images/others/company.png"
                                                class="wd-60 ht-60"
                                            />
                                        </figure>

                                        <!-- Text Information -->
                                        <div class="job-top-info flex-grow-1">
                                            <h5 class="mb-1">
                                                {{ item?.hospitalName }}
                                            </h5>
                                            <p class="mb-1">
                                                {{ item?.typeName }} : {{ item?.licenseTypeAcronym }} -
                                                {{ item.specialityName }}
                                            </p>
                                            <p class="mb-1" *ngIf="item?.shift">
                                                {{ item.shift }} | {{ item?.date }} - {{ item?.time }}
                                            </p>
                                            <div class="d-flex align-items-center">
                                                <figure class="me-2">
                                                    <img
                                                        *ngIf="item?.clinicianProfileUrl"
                                                        src="{{ item.clinicianProfileUrl }}"
                                                        alt="User image"
                                                        class="wd-40 ht-40 rounded-circle"
                                                    />
                                                    <img
                                                        *ngIf="item?.clinicianProfileUrl == null"
                                                        src="./assets/images/others/blank.png"
                                                        class="wd-40 ht-40 rounded-circle"
                                                    />
                                                </figure>
                                                <div>
                                                    <p class="mb-0">Chat with {{ item?.clinicianName }}</p>
                                                    <p
                                                        class="mt-1 mb-0 text-danger"
                                                        *ngIf="item?.unreadMessageCount > 0"
                                                    >
                                                        {{ item.unreadMessageCount }} unread message
                                                    </p>
                                                    <p
                                                        class="mt-1 mb-0 text-muted"
                                                        *ngIf="item?.unreadMessageCount == 0 && item?.createdOn"
                                                    >
                                                        Last message:
                                                        {{ item?.createdOn | roleBasedTimezone : 'ordinal' }}
                                                        -
                                                        {{ item.createdOn | roleBasedTimezone : 'shortTime' }}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="ms-auto">
                                            <i class="fas fa-chevron-right"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </li>
                <li [ngbNavItem]="4" class="" (click)="onNavChange(4)">
                    <a ngbNavLink class="">General</a>
                    <ng-template ngbNavContent>
                        <div *ngIf="chatList.length == 0">
                            <div class="no-data-available">
                                <figure>
                                    <img src="assets/logo/NoListFound.png" alt="" />
                                </figure>
                                <h5>No Data Found</h5>
                            </div>
                        </div>
                        <div
                            *ngIf="chatList.length > 0"
                            #scrollable
                            class="job-profile-card-list"
                            (scroll)="onScroll()"
                        >
                            <div class="document-list" *ngFor="let item of chatList">
                                <div
                                    class="all-job-box card p-3 rounded-0 shadow-none h-100 d-flex flex-column cursor-pointer"
                                    (click)="onChatDetails(item)"
                                >
                                    <div class="job-top-box d-flex align-items-center">
                                        <!-- Center Image -->
                                        <figure class="me-3">
                                            <img
                                                *ngIf="item?.hospitalProfileUrl"
                                                src="{{ item.hospitalProfileUrl }}"
                                                alt="Center image"
                                                class="wd-60 ht-60"
                                            />
                                            <img
                                                *ngIf="!item?.hospitalProfileUrl"
                                                src="assets/images/others/company.png"
                                                class="wd-60 ht-60"
                                            />
                                        </figure>

                                        <!-- Text Information -->
                                        <div class="job-top-info flex-grow-1">
                                            <h5 class="mb-1">
                                                {{ item?.hospitalName }}
                                            </h5>

                                            <p class="mb-1" *ngIf="item?.shift">
                                                {{ item.shift }} | {{ item?.date }} - {{ item?.time }}
                                            </p>
                                            <div class="d-flex align-items-center">
                                                <figure class="me-2">
                                                    <img
                                                        *ngIf="item?.clinicianProfileUrl"
                                                        src="{{ item.clinicianProfileUrl }}"
                                                        alt="User image"
                                                        class="wd-40 ht-40 rounded-circle"
                                                    />
                                                    <img
                                                        *ngIf="item?.clinicianProfileUrl == null"
                                                        src="./assets/images/others/blank.png"
                                                        class="wd-40 ht-40 rounded-circle"
                                                    />
                                                </figure>
                                                <div>
                                                    <p class="mb-0">Chat with {{ item?.clinicianName }}</p>
                                                    <p
                                                        class="mt-1 mb-0 text-danger"
                                                        *ngIf="item?.unreadMessageCount > 0"
                                                    >
                                                        {{ item.unreadMessageCount }} unread message
                                                    </p>
                                                    <p
                                                        class="mt-1 mb-0 text-muted"
                                                        *ngIf="item?.unreadMessageCount == 0 && item?.createdOn"
                                                    >
                                                        Last message:
                                                        {{ item?.createdOn | roleBasedTimezone : 'ordinal' }}
                                                        -
                                                        {{ item.createdOn | roleBasedTimezone : 'shortTime' }}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="ms-auto">
                                            <i class="fas fa-chevron-right"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </li>
            </ul>
        </div>
        <div [ngbNavOutlet]="defaultNav" class="p-0"></div>
    </div>

    <div class="modal-footer">
        <button
            class="btn btn-outline-primary fw-semibold px-4 px-md-5 mr-3 mr-md-4"
            type="button"
            (click)="dismissModel()"
        >
            Close
        </button>
    </div>
</div>
