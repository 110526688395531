import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BaseComponent } from './views/layout/base/base.component';
import { AuthGuard } from './core/guard/auth.guard';
import { UnsavedChangesGuard } from './core/guard/unsavedChanges.guard';
import { ErrorPageComponent } from './views/pages/error-page/error-page.component';

const routes: Routes = [
    { path: 'auth', loadChildren: () => import('./views/pages/auth/auth.module').then((m) => m.AuthModule) },
    {
        path: 'privacy-policy',
        loadChildren: () => import('./views/pages/auth/policy/policy.module').then((m) => m.PolicyModule),
    },
    {
        path: '',
        component: BaseComponent,
        canActivate: [AuthGuard],
        canDeactivate: [UnsavedChangesGuard],
        children: [
            {
                path: 'state',
                loadChildren: () => import('./views/pages/master/region/state/state.module').then((m) => m.StateModule),
                canActivate: [AuthGuard],
            },
            {
                path: 'city',
                loadChildren: () => import('./views/pages/master/region/city/city.module').then((m) => m.CityModule),
                canActivate: [AuthGuard],
            },
            {
                path: 'speciality',
                loadChildren: () =>
                    import('./views/pages/master/job-speciality/job-speciality.module').then(
                        (m) => m.JobSpecialityModule
                    ),
                canActivate: [AuthGuard],
            },
            {
                path: 'timezone',
                loadChildren: () =>
                    import('./views/pages/master/timezone/timezone.module').then((m) => m.TimezoneModule),
                canActivate: [AuthGuard],
            },
            {
                path: 'license-type',
                loadChildren: () =>
                    import('./views/pages/master/job-type/job-type.module').then((m) => m.JobTypeModule),
                canActivate: [AuthGuard],
            },
            {
                path: 'edit-profile',
                loadChildren: () => import('./views/pages/my-profile/my-profile.module').then((m) => m.MyProfileModule),
                canActivate: [AuthGuard],
            },
            {
                path: 'address',
                loadChildren: () => import('./views/pages/address/address.module').then((m) => m.AddressModule),
                canActivate: [AuthGuard],
            },
            {
                path: 'change-password',
                loadChildren: () =>
                    import('./views/pages/change-password/change-password.module').then((m) => m.ChangePasswordModule),
                canActivate: [AuthGuard],
            },
            {
                path: 'policy-type',
                loadChildren: () =>
                    import('./views/pages/master/policy-type/policy-type.module').then((m) => m.PolicyTypeModule),
                canActivate: [AuthGuard],
            },
            {
                path: 'public-holiday',
                loadChildren: () =>
                    import('./views/pages/master/public-holiday/public-holiday.module').then((m) => m.HolidayModule),
                canActivate: [AuthGuard],
            },
            {
                path: 'credential-category',
                loadChildren: () =>
                    import('./views/pages/master/credential-list/credential-list.module').then(
                        (m) => m.CredentialModule
                    ),
                canActivate: [AuthGuard],
            },
            {
                path: 'thrive-user-profile',
                loadChildren: () =>
                    import('./views/pages/thrive-user-profile/thrive-user-profile.module').then(
                        (m) => m.ThriveUserProfileModule
                    ),
            },
            {
                path: 'contact',
                loadChildren: () => import('./views/pages/contact/contact.module').then((m) => m.ContactModule),
                canActivate: [AuthGuard],
            },
            {
                path: 'notification',
                loadChildren: () =>
                    import('./views/pages/notification/notification.module').then((m) => m.NotificationModule),
                canActivate: [AuthGuard],
            },
            {
                path: 'unit-profile',
                loadChildren: () =>
                    import('./views/pages/unit-profile/unit-profile.module').then((m) => m.UnitProfileModule),
                canActivate: [AuthGuard],
            },
            {
                path: 'recieve-notification',
                loadChildren: () =>
                    import('./views/pages/receive-notification/receive-notification.module').then(
                        (m) => m.ReceiveNotificationModule
                    ),
                canActivate: [AuthGuard],
            },
            {
                path: 'credential-type',
                loadChildren: () =>
                    import('./views/pages/credential-name/credential-name.module').then((m) => m.CredentialNameModule),
                canActivate: [AuthGuard],
            },
            {
                path: 'notice-board',
                loadChildren: () =>
                    import('./views/pages/notice-board/notice-board.module').then((m) => m.NoticeBoardModule),
                canActivate: [AuthGuard],
            },
            {
                path: 'active-nurse',
                loadChildren: () =>
                    import('./views/pages/staff/staff-list/staff-list.module').then((m) => m.StaffListModule),
            },
            {
                path: 'shift',
                loadChildren: () => import('./views/pages/shift/shift.module').then((m) => m.ShiftModule),
                canActivate: [AuthGuard],
            },
            {
                path: 'facility-contact',
                loadChildren: () =>
                    import('./views/pages/hospital-contact/contact.module').then((m) => m.ContactModule),
                canActivate: [AuthGuard],
            },
            {
                path: 'other-contact',
                loadChildren: () =>
                    import('./views/pages/other-contact/other-contact.module').then((m) => m.OtherContactModule),
                canActivate: [AuthGuard],
            },
            {
                path: 'feedback-form',
                loadChildren: () =>
                    import('./views/pages/master/feedback-form/feedback-form.module').then((m) => m.FeedbackFormModule),
                canActivate: [AuthGuard],
            },
            {
                path: 'time-sheet',
                loadChildren: () => import('./views/pages/timesheet/timesheet.module').then((m) => m.TimesheetModule),
                canActivate: [AuthGuard],
            },
            {
                path: 'assets',
                loadChildren: () => import('./views/pages/master/assets/assets.module').then((m) => m.AssetsModule),
                canActivate: [AuthGuard],
            },
            {
                path: 'time-sheet-dispute-reason',
                loadChildren: () =>
                    import('./views/pages/master/time-sheet-form/time-sheet-form.module').then(
                        (m) => m.TimeSheetFormModule
                    ),
                canActivate: [AuthGuard],
            },
            {
                path: 'background-check-type',
                loadChildren: () =>
                    import('./views/pages/master/background-check-type/background-check-type.module').then(
                        (m) => m.BackgroundCheckTypeModdule
                    ),
                canActivate: [AuthGuard],
            },
            {
                path: 'graduation-type',
                loadChildren: () =>
                    import('./views/pages/master/graduation-type/graduation-type.module').then(
                        (m) => m.GraduationTypeModule
                    ),
                canActivate: [AuthGuard],
            },
            {
                path: 'custom-credential-type',
                loadChildren: () =>
                    import('./views/pages/master/custom-credential-type/custom-credential-type.module').then(
                        (m) => m.CustomCredentialTypeModule
                    ),
            },
            {
                path: 'counties',
                loadChildren: () =>
                    import('./views/pages/master/region/county/county.module').then((m) => m.CountyModule),
            },
            {
                path: 'unit-type',
                loadChildren: () =>
                    import('./views/pages/master/unit-type/unit-type.module').then((m) => m.UnitTypeModule),
                canActivate: [AuthGuard],
            },
            {
                path: 'healthcare-users',
                loadChildren: () =>
                    import('./views/pages/healthcare-contact/healthcare-contact.module').then(
                        (m) => m.HealthcareContactModule
                    ),
            },
            {
                path: 'region',
                loadChildren: () => import('./views/pages/master/market/market.module').then((m) => m.MarketModule),
                canActivate: [AuthGuard],
            },
            {
                path: 'custom-holiday',
                loadChildren: () =>
                    import('./views/pages/master/custom-holiday/custom-holiday.module').then(
                        (m) => m.CustomHolidayModule
                    ),
                canActivate: [AuthGuard],
            },
            {
                path: 'healthcare-system',
                loadChildren: () =>
                    import('./views/pages/healthcare-system/healthcare-system.module').then(
                        (m) => m.HealthcareSystemModule
                    ),
            },
            {
                path: 'roles',
                loadChildren: () =>
                    import('./views/pages/master/roles-and-permissions/roles-and-permissions.module').then(
                        (m) => m.RolesAndPermissionModule
                    ),
            },
            {
                path: 'coming-soon',
                loadChildren: () =>
                    import('./views/pages/coming-soon/coming-soon.module').then((m) => m.ComingSoonModule),
            },
            {
                path: '',
                loadChildren: () =>
                    import('./views/pages/landing-page/landing-page.module').then((m) => m.LandingPageModule),
                pathMatch: 'full',
            },
            {
                path: 'all-jobs',
                loadChildren: () => import('./views/pages/job/job.module').then((m) => m.JobModule),
                canActivate: [AuthGuard],
            },
            {
                path: 'custom-roles',
                loadChildren: () =>
                    import('./views/pages/master/custom-roles/custom-roles.module').then((m) => m.CustomRolesModule),
                canActivate: [AuthGuard],
            },
            {
                path: 'chat',
                loadChildren: () => import('./views/pages/chat/chat.module').then((m) => m.ChatModule),
                canActivate: [AuthGuard],
            },
            {
                path: 'restricted-nurse',
                loadChildren: () =>
                    import('./views/pages/staff/restricted-staff-list/restricted-staff-list.module').then(
                        (m) => m.RestrictedStaffListModule
                    ),
            },
            {
                path: 'lead-nurse',
                loadChildren: () =>
                    import('./views/pages/staff/all-nurse/all-nurse.module').then((m) => m.AllNurseModule),
            },

            {
                path: 'audit-trail',
                loadChildren: () =>
                    import('./views/pages/audit-trail/audit-trail.module').then((m) => m.AuditTrailModule),
            },

            {
                path: 'credential-report',
                loadChildren: () =>
                    import('./views/pages/credential-report/credential-report.module').then(
                        (m) => m.CredentialReportModule
                    ),
            },
            {
                path: 'nurse-profile',
                loadChildren: () =>
                    import('./views/pages/staff/view-profile/view-profile.module').then((m) => m.ViewProfileModule),
            },
            {
                path: 'add-nurse',
                loadChildren: () =>
                    import('./views/pages/staff/add-nurse/add-nurse.module').then((m) => m.AddNurseModule),
            },
            {
                path: 'former-nurses',
                loadChildren: () =>
                    import('./views/pages/staff/former-nurse/former-nurse.module').then((m) => m.FormerNurseModule),
            },
            {
                path: 'bulk-upload',
                loadChildren: () =>
                    import('./views/pages/bulk-upload/bulk-upload.module').then((m) => m.BulkUploadModule),
            },
            // { path: '**', redirectTo: 'dashboard', pathMatch: 'full' }
        ],
    },
    {
        path: 'error',
        component: ErrorPageComponent,
        data: {
            type: 404,
            title: 'Page Not Found',
            desc: "Oopps!! The page you were looking for doesn't exist.",
        },
    },
    {
        path: 'error/:type',
        component: ErrorPageComponent,
    },
    { path: '**', redirectTo: 'error', pathMatch: 'full' },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top' })],
    exports: [RouterModule],
})
export class AppRoutingModule {}
